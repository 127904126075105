import {animate, group, query, style, transition, trigger} from "@angular/animations";

export const routeAnimation = trigger('routeAnimation', [
  transition('* => *', [
    query(':enter, :leave', style({
      position: 'absolute',
      top: '16px',
      left: '16px',
      right: '16px',
      minHeight: 'calc(100% - 32px)'
    }), {optional: true}),
    group([
      query(':enter', [
        style({transform: 'translateX(calc(100% + 32px))'}),
        animate('500ms ease-in-out', style({transform: 'translateX(0)'}))
      ], {optional: true}),
      query(':leave', [
        style({transform: 'translateX(0)'}),
        animate('500ms ease-in-out', style({transform: 'translateX(calc(-100% - 32px))'}))
      ], {optional: true})
    ])
  ])
]);
