import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {PaginatedResponse} from "../../../models/paginated.model";
import {buildUrl} from "../../../utils/build-url";
import {
  AssociationListParams,
  AssociationModel,
  SpecialistAssociationCreateModel,
  SpecialistAssociationModel,
  SpecialistAssociationUpdateModel
} from "../../association/association.types";

@Injectable({
  providedIn: 'root'
})
export class AssociationService {
  constructor(private readonly http: HttpClient) {
  }

  list(specialistId: string, params: { per_page: '0' } & AssociationListParams): Observable<Array<SpecialistAssociationModel>>;
  list(specialistId: string, params?: AssociationListParams): Observable<PaginatedResponse<SpecialistAssociationModel>>;
  list(specialistId: string, params?: AssociationListParams): Observable<Array<AssociationModel> | PaginatedResponse<SpecialistAssociationModel>> {
    return this.http.get<Array<AssociationModel> | PaginatedResponse<SpecialistAssociationModel>>(buildUrl('specialist', specialistId, 'association'), {params});
  }

  lookup(specialistId: string, params: { per_page: '0' } & AssociationListParams): Observable<Array<SpecialistAssociationModel>>;
  lookup(specialistId: string, params?: AssociationListParams): Observable<PaginatedResponse<SpecialistAssociationModel>>;
  lookup(specialistId: string, params?: AssociationListParams): Observable<Array<AssociationModel> | PaginatedResponse<SpecialistAssociationModel>> {
    return this.http.post<Array<SpecialistAssociationModel> | PaginatedResponse<SpecialistAssociationModel>>(buildUrl('specialist', specialistId, 'association', 'search'), params);
  }

  show(specialistId: string, associationId: string): Observable<SpecialistAssociationModel> {
    return this.http.get<SpecialistAssociationModel>(buildUrl('specialist', specialistId, 'association', associationId));
  }

  create(specialistId: string, data?: SpecialistAssociationCreateModel): Observable<SpecialistAssociationModel> {
    return this.http.post<SpecialistAssociationModel>(buildUrl('specialist', specialistId, 'association'), data);
  }

  update(specialistId: string, associationId: string, data?: SpecialistAssociationUpdateModel): Observable<SpecialistAssociationModel> {
    return this.http.put<SpecialistAssociationModel>(buildUrl('specialist', specialistId, 'association', associationId), data);
  }

  delete(specialistId: string, associationId: string): Observable<undefined> {
    return this.http.delete<undefined>(buildUrl('specialist', specialistId, 'association', associationId));
  }

  can(specialistId: string, permission: 'list' | 'create'): Observable<{ authorized: boolean }>;
  can(specialistId: string, permission: 'view' | 'update' | 'delete', associationId: string): Observable<{ authorized: boolean }>;
  can(specialistId: string, permission: 'list' | 'create' | 'view' | 'update' | 'delete', associationId?: string): Observable<{ authorized: boolean }> {
    if (permission === 'list' || permission === 'create') {
      return this.http.get<{ authorized: boolean }>(buildUrl('specialist', specialistId, 'association', 'can', permission));
    }

    return this.http.get<{ authorized: boolean }>(buildUrl('specialist', specialistId, 'association', 'can', permission, associationId));
  }
}
