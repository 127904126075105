import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {PaginatedResponse} from "../../../../models/paginated.model";
import {buildUrl} from "../../../../utils/build-url";
import {QuestionnaireListParams, QuestionnaireModel, RegularModel} from "../../../questionnaire/questionnaire.types";

import {QuestionnaireCreateModel} from "./questionnaire.types";

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  constructor(private readonly http: HttpClient) {
  }

  list(specialistId: string, dossierId: string, params: { per_page: '0' } & QuestionnaireListParams): Observable<Array<RegularModel>>;
  list(specialistId: string, dossierId: string, params?: { per_page?: string } & QuestionnaireListParams): Observable<PaginatedResponse<RegularModel>>;
  list(specialistId: string, dossierId: string, params?: QuestionnaireListParams): Observable<Array<RegularModel> | PaginatedResponse<RegularModel>> {
    return this.http.get<Array<RegularModel> | PaginatedResponse<RegularModel>>(buildUrl('specialist', specialistId, 'dossier', dossierId, 'questionnaire'), {params});
  }

  lookup(specialistId: string, dossierId: string, params: { per_page: '0' } & QuestionnaireListParams): Observable<Array<RegularModel>>;
  lookup(specialistId: string, dossierId: string, params?: { per_page?: string } & QuestionnaireListParams): Observable<PaginatedResponse<RegularModel>>;
  lookup(specialistId: string, dossierId: string, params?: QuestionnaireListParams): Observable<Array<RegularModel> | PaginatedResponse<RegularModel>> {
    return this.http.post<Array<RegularModel> | PaginatedResponse<RegularModel>>(buildUrl('specialist', specialistId, 'dossier', dossierId, 'questionnaire', 'search'), params);
  }

  create(specialistId: string, dossierId: string, data?: QuestionnaireCreateModel): Observable<QuestionnaireModel> {
    return this.http.post<QuestionnaireModel>(buildUrl('specialist', specialistId, 'dossier', dossierId, 'questionnaire'), data);
  }

  delete(specialistId: string, dossierId: string, id: string): Observable<undefined> {
    return this.http.delete<undefined>(buildUrl('specialist', specialistId, 'dossier', dossierId, 'questionnaire', id));
  }

  can(specialistId: string, dossierId: string, permission: 'list' | 'create'): Observable<{ authorized: boolean }>;
  can(specialistId: string, dossierId: string, permission: 'delete', id: string): Observable<{ authorized: boolean }>;
  can(specialistId: string, dossierId: string, permission: 'list' | 'create' | 'delete', id?: string): Observable<{ authorized: boolean }> {
    if (permission === 'list' || permission === 'create') {
      return this.http.get<{ authorized: boolean }>(buildUrl('specialist', specialistId, 'dossier', dossierId, 'questionnaire', 'can', permission));
    }

    return this.http.get<{ authorized: boolean }>(buildUrl('specialist', specialistId, 'dossier', dossierId, 'questionnaire', 'can', permission, id));
  }
}
