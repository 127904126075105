import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {PaginatedResponse} from "../../models/paginated.model";
import {buildUrl} from "../../utils/build-url";
import {AssociationModel} from "../association/association.types";

import {
  InviteConfirmModel,
  InviteCreateModel,
  InviteListParams,
  InviteModel,
  InviteShowParams,
  InviteUpdateModel
} from "./invite.types";

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  constructor(private readonly http: HttpClient) {
  }

  list(params?: InviteListParams): Observable<PaginatedResponse<InviteModel>> {
    return this.http.get<PaginatedResponse<InviteModel>>(buildUrl('invite'), {params});
  }

  lookup(params?: InviteListParams): Observable<PaginatedResponse<InviteModel>> {
    return this.http.post<PaginatedResponse<InviteModel>>(buildUrl('invite', 'search'), params);
  }

  show(id: string, params?: InviteShowParams): Observable<InviteModel> {
    return this.http.get<InviteModel>(buildUrl('invite', id), {params});
  }

  create(data?: InviteCreateModel): Observable<InviteModel> {
    return this.http.post<InviteModel>(buildUrl('invite'), data);
  }

  update(id: string, data?: InviteUpdateModel): Observable<InviteModel> {
    return this.http.put<InviteModel>(buildUrl('invite', id), data);
  }

  delete(id: string): Observable<undefined> {
    return this.http.delete<undefined>(buildUrl('invite', id));
  }

  associations(id: string, search: string, authCode?: string): Observable<PaginatedResponse<AssociationModel>> {
    return this.http.post<PaginatedResponse<AssociationModel>>(buildUrl('invite', id, 'association'), {q: search}, authCode ? {params: {auth_code: authCode}} : {});
  }

  confirm(id: string, data: InviteConfirmModel, authCode?: string): Observable<undefined> {
    return this.http.post<undefined>(buildUrl('invite', id, 'confirm'), data, authCode ? {params: {auth_code: authCode}} : {});
  }

  unique(id: string, field: 'username' | 'email', value: string, authCode?: string): Observable<{ valid: boolean }> {
    return this.http.post<{ valid: boolean }>(buildUrl('invite', id, 'unique'), {
      field,
      value,
    }, authCode ? {params: {auth_code: authCode}} : {});
  }

  can(permission: 'list' | 'create'): Observable<{ authorized: boolean }>;
  can(permission: 'view' | 'update' | 'delete', inviteId: string): Observable<{ authorized: boolean }>;
  can(permission: 'list' | 'create' | 'view' | 'update' | 'delete', inviteId?: string): Observable<{ authorized: boolean }> {
    if (permission === 'list' || permission === 'create') {
      return this.http.get<{ authorized: boolean }>(buildUrl('invite', 'can', permission));
    }

    return this.http.get<{ authorized: boolean }>(buildUrl('invite', 'can', permission, inviteId));
  }
}
