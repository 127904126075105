import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {PaginatedResponse} from "../../../models/paginated.model";
import {buildUrl} from "../../../utils/build-url";
import {SurveyResultModel} from "../../questionnaire/questionnaire.types";

import {ReportModel, ReportParams, SurveyResultListParams} from "./survey-result.types";

@Injectable({
  providedIn: 'root'
})
export class SurveyResultService {
  constructor(private readonly http: HttpClient) {
  }

  list(specialistId: string, params?: { per_page: '0' } & SurveyResultListParams): Observable<Array<SurveyResultModel>>;
  list(specialistId: string, params?: { per_page?: string } & SurveyResultListParams): Observable<PaginatedResponse<SurveyResultModel>>;
  list(specialistId: string, params?: SurveyResultListParams): Observable<Array<SurveyResultModel> | PaginatedResponse<SurveyResultModel>> {
    return this.http.get<Array<SurveyResultModel> | PaginatedResponse<SurveyResultModel>>(buildUrl('specialist', specialistId, 'survey'), {params});
  }

  lookup(specialistId: string, params?: { per_page: '0' } & SurveyResultListParams): Observable<Array<SurveyResultModel>>;
  lookup(specialistId: string, params?: { per_page?: string } & SurveyResultListParams): Observable<PaginatedResponse<SurveyResultModel>>;
  lookup(specialistId: string, params?: SurveyResultListParams): Observable<Array<SurveyResultModel> | PaginatedResponse<SurveyResultModel>> {
    return this.http.post<Array<SurveyResultModel> | PaginatedResponse<SurveyResultModel>>(buildUrl('specialist', specialistId, 'survey', 'search'), params);
  }

  show(specialistId: string, id: string): Observable<SurveyResultModel> {
    return this.http.get<SurveyResultModel>(buildUrl('specialist', specialistId, 'survey', id));
  }

  report(specialistId: string, params?: ReportParams): Observable<ReportModel> {
    return this.http.get<ReportModel>(buildUrl('specialist', specialistId, 'survey', 'report'), {params});
  }

  reportLookup(specialistId: string, params?: ReportParams): Observable<ReportModel> {
    return this.http.post<ReportModel>(buildUrl('specialist', specialistId, 'survey', 'report'), params);
  }

  can(specialistId: string, permission: 'list' | 'create'): Observable<{ authorized: boolean }>;
  can(specialistId: string, permission: 'view' | 'update' | 'delete', id: string): Observable<{ authorized: boolean }>;
  can(specialistId: string, permission: 'list' | 'create' | 'view' | 'update' | 'delete', id?: string): Observable<{ authorized: boolean }> {
    if (permission === 'list' || permission === 'create') {
      return this.http.get<{ authorized: boolean }>(buildUrl('specialist', specialistId, 'survey', 'can', permission));
    }

    return this.http.get<{ authorized: boolean }>(buildUrl('specialist', specialistId, 'survey', 'can', permission, id));
  }
}
