import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {PaginatedResponse} from "../../models/paginated.model";
import {buildUrl} from "../../utils/build-url";

import {
  ClientIntakeModel,
  ClientIntakeUpdateModel,
  QuestionnaireListParams,
  QuestionnaireModel,
  QuestionnaireType,
  RegularCreateModel,
  RegularModel,
  RegularUpdateModel,
  SpecialistIntakeModel,
  SpecialistIntakeUpdateModel,
  SurveyModel,
  SurveyUpdateModel,
  UpdateModel
} from "./questionnaire.types";

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  constructor(private readonly http: HttpClient) {
  }

  list(params?: QuestionnaireListParams): Observable<PaginatedResponse<RegularModel>> {
    return this.http.get<PaginatedResponse<RegularModel>>(buildUrl('questionnaire'), {params});
  }

  lookup(params: QuestionnaireListParams & { per_page: '0' }): Observable<Array<RegularModel>>;
  lookup(params?: QuestionnaireListParams): Observable<PaginatedResponse<RegularModel>>;
  lookup(params?: QuestionnaireListParams): Observable<Array<RegularModel> | PaginatedResponse<RegularModel>> {
    return this.http.post<Array<RegularModel> | PaginatedResponse<RegularModel>>(buildUrl('questionnaire', 'search'), params);
  }

  create(data?: RegularCreateModel): Observable<RegularModel> {
    return this.http.post<RegularModel>(buildUrl('questionnaire'), data);
  }

  show(questionnaireId: QuestionnaireType.CLIENT_INTAKE, params?: {repeat: '0' | '1'}): Observable<ClientIntakeModel>;
  show(questionnaireId: QuestionnaireType.SPECIALIST_INTAKE): Observable<SpecialistIntakeModel>;
  show(questionnaireId: QuestionnaireType.SURVEY): Observable<SurveyModel>;
  show(questionnaireId: string): Observable<RegularModel>;
  show(questionnaireId: QuestionnaireType.CLIENT_INTAKE | QuestionnaireType.SPECIALIST_INTAKE | QuestionnaireType.SURVEY | string, params?: { [key: string]: string }): Observable<QuestionnaireModel> {
    return this.http.get<QuestionnaireModel>(buildUrl('questionnaire', questionnaireId), {params});
  }

  update(questionnaireId: QuestionnaireType.CLIENT_INTAKE, data: ClientIntakeUpdateModel): Observable<ClientIntakeModel>;
  update(questionnaireId: QuestionnaireType.SPECIALIST_INTAKE, data: SpecialistIntakeUpdateModel): Observable<SpecialistIntakeModel>;
  update(questionnaireId: QuestionnaireType.SURVEY, data: SurveyUpdateModel): Observable<SurveyModel>;
  update(questionnaireId: string, data: RegularUpdateModel): Observable<RegularModel>;
  update(questionnaireId: QuestionnaireType.CLIENT_INTAKE | QuestionnaireType.SPECIALIST_INTAKE | QuestionnaireType.SURVEY | string, data: UpdateModel): Observable<QuestionnaireModel> {
    return this.http.put<QuestionnaireModel>(buildUrl('questionnaire', questionnaireId), data);
  }

  delete(questionnaireId: string): Observable<undefined> {
    return this.http.delete<undefined>(buildUrl('questionnaire', questionnaireId));
  }

  can(permission: 'list' | 'create'): Observable<{ authorized: boolean }>;
  can(permission: 'view' | 'delete' | 'update', questionnaireId: string): Observable<{ authorized: boolean }>;
  can(permission: 'list' | 'create' | 'view' | 'update' | 'delete', questionnaireId?: string): Observable<{ authorized: boolean }> {
    if (permission === 'list' || permission === 'create') {
      return this.http.get<{ authorized: boolean }>(buildUrl('questionnaire', 'can', permission));
    }

    return this.http.get<{ authorized: boolean }>(buildUrl('questionnaire', 'can', permission, questionnaireId));
  }
}
