import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {buildUrl} from "../../utils/build-url";
import {
  ClientIntakeModel,
  ClientIntakeResultModel,
  RegularModel,
  RegularResultModel,
  ResultUpdateModel,
  SurveyModel,
  SurveyResultModel,
  SurveyResultUpdateModel
} from "../questionnaire/questionnaire.types";
import {SpecialistModel} from "../specialist/specialist.types";

@Injectable({
  providedIn: 'root'
})
export class ClientMailService {
  constructor(private readonly http: HttpClient) {
  }

  getSpecialist(dossierId: string): Observable<SpecialistModel> {
    return this.http.get<SpecialistModel>(buildUrl('mail', dossierId, 'specialist'));
  }

  getIntake(dossierId: string, appointmentId: string): Observable<ClientIntakeModel> {
    return this.http.get<ClientIntakeModel>(buildUrl('mail', dossierId, 'intake', appointmentId));
  }

  getIntakeResult(dossierId: string, appointmentId: string): Observable<ClientIntakeResultModel> {
    return this.http.get<ClientIntakeResultModel>(buildUrl('mail', dossierId, 'intake', appointmentId, 'result'));
  }

  saveIntakeResult(dossierId: string, appointmentId: string, result: ResultUpdateModel): Observable<ClientIntakeResultModel> {
    return this.http.post<ClientIntakeResultModel>(buildUrl('mail', dossierId, 'intake', appointmentId, 'result'), result);
  }

  getQuestionnaire(dossierId: string, questionnaireId: string): Observable<RegularModel> {
    return this.http.get<RegularModel>(buildUrl('mail', dossierId, 'questionnaire', questionnaireId));
  }

  getQuestionnaireResult(dossierId: string, questionnaireId: string): Observable<RegularResultModel> {
    return this.http.get<RegularResultModel>(buildUrl('mail', dossierId, 'questionnaire', questionnaireId, 'result'));
  }

  saveQuestionnaireResult(dossierId: string, questionnaireId: string, result: ResultUpdateModel): Observable<RegularResultModel> {
    return this.http.post<RegularResultModel>(buildUrl('mail', dossierId, 'questionnaire', questionnaireId, 'result'), result);
  }

  getSurvey(dossierId: string, appointmentId: string): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(buildUrl('mail', dossierId, 'survey', appointmentId));
  }

  getSurveyResult(dossierId: string, appointmentId: string): Observable<SurveyResultModel> {
    return this.http.get<SurveyResultModel>(buildUrl('mail', dossierId, 'survey', appointmentId, 'result'));
  }

  saveSurveyResult(dossierId: string, appointmentId: string, result: SurveyResultUpdateModel): Observable<SurveyResultModel> {
    return this.http.post<SurveyResultModel>(buildUrl('mail', dossierId, 'survey', appointmentId, 'result'), result);
  }
}
