import {OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {takeUntil} from "rxjs/operators";

import {AppointmentModel} from "../../../../../models/appointment.model";
import {AuthService} from "../../../../../services/auth/auth.service";
import {UserService} from "../../../../../services/user/user.service";
import {ClientDetailBase} from "../../client-detail-base.class";
import {ClientDetailComponent} from "../../client-detail.component";

import {ConsultDetailComponent} from "./consult-detail.component";

export abstract class ConsultDetailBase extends ClientDetailBase implements OnInit {
  appointment: AppointmentModel;

  protected constructor(protected readonly router: Router,
                        translate: TranslateService,
                        authService: AuthService,
                        userService: UserService,
                        clientDetail: ClientDetailComponent,
                        protected readonly consultDetail: ConsultDetailComponent) {
    super(router, translate, authService, userService, clientDetail);
  }

  ngOnInit(): void {
    this.consultDetail.appointment$.pipe(takeUntil(this.destroy))
      .subscribe(appointment => {
        this.appointment = appointment;
      });

    super.ngOnInit();
  }
}
