import {OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {combineLatest, Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {ProtectedComponent} from "../../classes/protected-page.class";
import {AssociationService} from "../../services/association/association.service";
import {AssociationModel} from "../../services/association/association.types";
import {AuthService} from "../../services/auth/auth.service";
import {ErrorService} from "../../services/error/error.service";
import {SpecialistService} from "../../services/specialist/specialist.service";
import {SpecialistModel} from "../../services/specialist/specialist.types";
import {UserService} from "../../services/user/user.service";

import {ProfileComponent} from "./profile.component";

export abstract class ProfileSectionComponent extends ProtectedComponent implements OnDestroy, OnInit {
  readonly loading$: Observable<boolean>;

  association: AssociationModel;
  specialist: SpecialistModel;

  actAs = false;

  protected readonly profileDataChange = new Subject<void>();

  protected constructor(protected readonly route: ActivatedRoute,
                        translate: TranslateService,
                        authService: AuthService,
                        protected readonly associationService: AssociationService,
                        protected readonly errorService: ErrorService,
                        protected readonly specialistService: SpecialistService,
                        userService: UserService,
                        protected readonly parent: ProfileComponent) {
    super(translate, authService, userService);

    this.loading$ = parent.loading$;
  }

  ngOnDestroy(): void {
    this.profileDataChange.complete();

    super.ngOnDestroy();
  }

  ngOnInit(): void {
    combineLatest([
      this.parent.association$,
      this.parent.specialist$,
      this.parent.actAs$,
    ])
      .pipe(
        takeUntil(this.destroy)
      )
      .subscribe(([association, specialist, actAs]) => {
        this.association = association;
        this.specialist = specialist;
        this.actAs = actAs;

        this.profileDataChange.next();
      });

    super.ngOnInit();
  }
}
