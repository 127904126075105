import {OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {takeUntil} from "rxjs/operators";

import {UserRole} from "../../../auth/auth.types";
import {ListComponent} from "../../../classes/list-page.class";
import {AuthService} from "../../../services/auth/auth.service";
import {DossierModel} from "../../../services/specialist/dossier/dossier.types";
import {UserService} from "../../../services/user/user.service";

import {ClientDetailComponent} from "./client-detail.component";

export abstract class ClientDetailList<T> extends ListComponent<T> implements OnInit {
  dossier: DossierModel;

  protected constructor(protected readonly router: Router,
                        translate: TranslateService,
                        authService: AuthService,
                        userService: UserService,
                        protected readonly parent: ClientDetailComponent) {
    super(translate, authService, userService);
  }

  ngOnInit(): void {
    this.roleChange.pipe(takeUntil(this.destroy))
      .subscribe(() => {
        if (this.userRole !== UserRole.SPECIALIST) {
          this.router.navigate(['/client'])
            .catch(reason => {
              console.warn(reason);
            });
        }
      });

    this.parent.dossier$.pipe(takeUntil(this.destroy))
      .subscribe(dossier => {
        this.dossier = dossier;

        this.loadData();
      });

    super.ngOnInit();
  }
}
